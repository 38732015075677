/*------------------------------------------------------------------
[Table of contents]
This file include css code for each section or custom css for element in each section and each page.

-------------------------------------------------------------------*/
/* #General class*/

#outline{
    font-family:Tahoma;
    font-weight: bold;
	color: beige;
	font-size: 70px;
	-webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: black;
}

#outline2{
    font-family: Tahoma;
    font-weight: bold;
	color: beige;
	font-size: 20px;
	-webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}