/* Overlay style */
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

/* Overlay closing cross */
.overlay .overlay-close {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 40px;
	top: 20px;
	font-size: 40px;
	line-height: 40px;
	transform: scale(0.1);
	border: none;
	background: transparent;
	color: inherit;
	outline: none;
	z-index: 100;
}

.overlay.open .overlay-close {
	transform: scale(1);
}

/* Menu style */
.overlay nav {
	text-align: center;
	position: relative;
	top: 50%;
	height: 60%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	height: 100%;
	position: relative;
}

.overlay ul li {
	display: block;
	height: 20%;
	height: calc(100% / 5);
	min-height: 54px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.overlay ul li a {
	font-size: 54px;
	font-weight: 300;
	display: block;
	color: #fff;
	-webkit-transition: color 0.2s;
	transition: color 0.2s;
}

.overlay ul li a:hover,
.overlay ul li a:focus {
	color: #f0f0f0;
}

/* Effects */
.overlay-scale {
	visibility: hidden;
	opacity: 0;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.overlay-scale.open {
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}
